import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    redirect: '',
    component: () => import('@/views/index.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/views/home/index.vue'),
        meta: {
          title: '北京市维泰律师事务所',
          key: 'index'
        }
      },
      {
        path: '/summary',
        name: 'summary',
        component: () => import('@/views/summary/index.vue'),
        meta: {
          title: '律所概述',
          key: "summary",
        },
      },
      {
        path: '/team',
        name: 'coreTeam',
        component: () => import('@/views/coreTeam/index.vue'),
        meta: {
          title: '核心团队',
          key: 'team'
        },
      },
      {
        path: '/teamDetails',
        name: 'teamDetails',
        component: () => import('@/views/coreTeam/details.vue'),
        meta: {
          title: '核心团队',
          key: 'team'
        },
      },
      {
        path: '/business',
        name: 'business',
        component: () => import('@/views/business/index.vue'),
        meta: {
          title: '核心业务',
          key: "business"
        },
      },
      {
        path: '/joinUs',
        name: 'joinUs',
        component: () => import('@/views/joinUs/index.vue'),
        meta: {
          title: '加入我们',
          key: "joinUs"
        },
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('@/views/about/index.vue'),
        meta: {
          title: '联系我们',
          key: "about"
        },
      },
      {
        path: '/businessDetails',
        name: 'businessDetails',
        component: () => import('@/views/business/details.vue'),
        meta: {
          title: '',
          key: "business"
        },
      },
    ],
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    (document as any).title = to.meta.title;
  }
  next();
  
});

export default router
